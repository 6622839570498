import { BrowserRouter, Route, Router, Routes } from "react-router-dom"
import Unreachable from '../pages/unreachable.page'
import Profile from '../pages/profile.page'
import Dashboard from '../pages/dashboard.page'
import Availability from '../pages/availability.page'
import Project from '../pages/project.page'
import Member from '../pages/member.page'
import MemberUpdate from '../pages/member-update.page'
import Timetable from '../pages/timetable.page'
import Support from '../pages/support.page'
import ProjectUpdate from '../pages/project-update.page'
import MenuComponent from '../common/components/menu.component';
import LoadingComponent from "../common/components/loading.component"
import { useAuth0 } from "@auth0/auth0-react"

export default () => {


  const { isLoading } = useAuth0();

  if (isLoading)
    return <LoadingComponent text='Authenticating user...' />

  return <>
    <BrowserRouter>
      <Routes>

        <Route path='/' element={
          <>
            <MenuComponent />
            <Project />
          </>
        } />

        <Route path='/project' element={
          <>
            <MenuComponent />
            <Project />
          </>
        } />

        <Route path='/member' element={
          <>
            <MenuComponent />
            <Member />
          </>
        } />

        <Route path='/availability' element={
          <>
            <MenuComponent />
            <Availability />
          </>
        } />

        <Route path='/timetable' element={
          <>
            <MenuComponent />
            <Timetable />
          </>
        } />

        <Route path='/profile' element={
          <>
            <MenuComponent />
            <Profile />
          </>
        } />

        <Route path='/support' element={
          <>
            <MenuComponent />
            <Support />
          </>
        } />

        <Route path='/project/:uuid' element={
          <ProjectUpdate />
        } />

        <Route path='/member/:uuid' element={
          <MemberUpdate />
        } />

        <Route path="*" element={<Unreachable />} />
      </Routes>
    </BrowserRouter>
  </>
}
