import { ActionButton, CheckboxVisibility, ConstrainMode, DetailsList, DetailsListLayoutMode, Label, Link, Persona, PersonaPresence, PersonaSize, ProgressIndicator, SelectionMode, Text } from "@fluentui/react"
import { useNavigate } from "react-router-dom"
import ProjectTableActionComponent from "./project-table-action.component";
import ProjectStatusComponent from "./project-status.component";

export default ({ searching, entities, refresh }) => {

  const columns = [
    {
      key: "id",
      name: "Project",
      onRender: item => <Label>{item.SequenceId}</Label>,
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
    },
    {
      key: "detail",
      name: "Detail",
      onRender: item => <>
        <Text variant="medium">
          {item.Location}
        </Text>
        <div>
          <Text variant="small">
            <b>
              {item.SequenceId % 8 + 1} Member(s)
            </b>
          </Text>
        </div>
      </>,
      minWidth: 350,
      maxWidth: 350,
      isResizable: true,
    },
    {
      key: "manager",
      name: "Manager",
      onRender: item => <Persona
        text={item.Manager.Name}
        secondaryText={item.Manager.Mail}
        size={PersonaSize.size40}
      />,

      isResizable: true,
    },
    {
      key: "status",
      name: "Status",
      onRender: item => <ProjectStatusComponent active={item.Active} />,
    },
    {
      key: "action",
      isResizable: true,
      onRender: item => <ProjectTableActionComponent item={item} />,
    }
  ]

  if (searching)
    return <ProgressIndicator label="Projects" description="Retrieving projects from organization..." />;

  if (Array.isArray(entities))
    return <DetailsList
      items={entities}
      layoutMode={DetailsListLayoutMode.justified}
      constrainMode={ConstrainMode.horizontalConstrained}
      columns={columns}
      selectionMode={SelectionMode.none}
    />
}