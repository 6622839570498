import { DefaultButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react"
import { useState } from "react"
import { AvailabilityService } from "../../services/availability.service";
import { NEVER } from "rxjs";

export default ({ searching, refresh }) => {

    const [creating, setCreating] = useState(undefined);

    const create = () => {

        const complete = () =>
            setTimeout(() => {
                setCreating(false);
                refresh()
            }, 1000);

        setCreating(true);

        AvailabilityService.create({})
            .then(response => console.log(response))
            .finally(complete)
    }

    return <PrimaryButton
        text={creating ? 'Creating...' : 'Create Availability'}
        onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : undefined}
        onClick={create}
        disabled={searching || creating}
    />
}