import { v4 } from 'uuid';

const names = ['Taylor Swift', 'Indiana Jones', 'Bill Gates', 'Steve Jobs', 'Elon Musk', 'Davy Jones', 'Will Turner', 'Elizabeth Swann', 'Will Smith', 'Brad Pitt', 'Angelina Jolie', 'Tom Cruise']
const occupations = ['Software Developer', 'Software Engineer', 'Web Developer', 'Web Designer', 'Database Administrator', 'Network Administrator', 'Systems Analyst', 'Systems Administrator', 'Project Manager'];

const entities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((uuid, index) => {

    const name = names[index];
    const [firstName, lastName] = name.split(' ');
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

    return {
        Id: index,
        Name: `${firstName} ${lastName}`,
        FirstName: firstName,
        LastName: lastName,
        Initials: initials,
        PhoneNumber: '+61 0426 280 597',
        Occupation: occupations[index % occupations.length],
        Mail: `${firstName.toLowerCase()}.${lastName.toLowerCase()}@company.com`,
        Active: [1, 2, 3, 6, 7, 8, 9].includes(index),
    }
});

// const service = `${process.env.EXPO_PUBLIC_SERVICE_URL}/api/operator-agreement`;
const service = `https://localhost:3000`;

const search = async () => {
    return entities;
}

const create = async (entity) => {
    return { success: true }
}

export const MemberService = {
    entities,
    create,
    search,
}
