import { DefaultButton, FontIcon, Spinner, SpinnerSize, Stack } from "@fluentui/react"

export default ({ children, searching, refresh }) => {
    return <div >
        <div className="ms-Grid" dir="rtl">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Stack horizontal={true} wrap={true} >
                        <DefaultButton
                            dir="ltr"
                            style={{ marginLeft: '10px' }}
                            text={searching ? 'Refreshing' : 'Refresh'}
                            onRenderIcon={() => searching ? <Spinner size={SpinnerSize.small} /> : <FontIcon iconName="Refresh" />}
                            onClick={refresh}
                            disabled={searching}
                        />
                        {children}
                    </Stack>
                </div>
            </div>
        </div>
    </div>
}