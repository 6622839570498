import { ActionButton, IconButton } from "@fluentui/react";
import TimetableConfirmationComponent from "./timetable-confirmation.component";

export default ({ item, refresh }) => {

    const menuProps = {
        items: [
            {
                key: 'confirmation',
                text: 'Confirm',
                iconProps: { iconName: 'EventAccepted' },
                onRender: () => <TimetableConfirmationComponent action={true} selection={[item]} refresh={refresh} />,
                disabled: item.Confirmed,
            }
        ],
        directionalHintFixed: true,
    };

    return <IconButton
        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 15 } }}
        menuProps={menuProps}
        disabled={false}
        checked={false}
    />
}