
import ProjectFilterComponent from '../components/project/project-filter.component'
import ProjectCreateComponent from '../components/project/project-create.component'
import ProjectTableComponent from '../components/project/project-table.component'
import { useEffect, useState } from 'react';
import { ProjectService } from '../services/project.service';
import AppointmentOptionsComponent from '../components/appointment/appointment-options.component';
import { Stack } from '@fluentui/react';

export default () => {

    const [entities, setEntities] = useState(undefined);
    const [searching, setSearching] = useState(undefined);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setSearching(true)
        const complete = () => setTimeout(() => setSearching(false), 1000);
        ProjectService.search().then((response) => setEntities(response)).finally(complete);
    }

    return <div>
        <ProjectFilterComponent refresh={refresh} >
            <Stack.Item>
                <AppointmentOptionsComponent />
            </Stack.Item>
            <Stack.Item>
                <ProjectCreateComponent searching={searching} refresh={refresh} />
            </Stack.Item>
        </ProjectFilterComponent>
        <ProjectTableComponent searching={searching} entities={entities} refresh={refresh}>
        </ProjectTableComponent>
    </div>
}
