import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { TeamService } from "../../services/team.service";
import MemberSelectorComponent from "../member/member-selector.component"
import { useState } from "react";


export default ({ searching, refresh }) => {

    const [creating, setCreating] = useState(undefined);

    const create = () => {

        const complete = () =>
            setTimeout(() => {
                setCreating(false);
                refresh()
            }, 1000);

        setCreating(true);

        TeamService.create({})
            .then(response => console.log(response))
            .finally(complete)
    }

    return <PrimaryButton
        text={creating ? 'Adding' : 'Add Member'}
        onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : undefined}
        onClick={create}
        disabled={searching || creating}
    />
}