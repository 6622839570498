import { ActionButton, FontIcon } from "@fluentui/react"

export default ({ active }) => {

    if (active)
        return <ActionButton iconProps={{ iconName: 'Completed', style: { color: 'green' } }}  >
            Active
        </ActionButton>

    return <ActionButton iconProps={{ iconName: 'Archive' }}  >
        Archived
    </ActionButton>
}