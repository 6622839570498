import { ActionButton, DefaultEffects, Label } from "@fluentui/react"

export default () => {
    return <div style={{ marginTop: 10, padding: 10, boxShadow: DefaultEffects.elevation8 }}>
        <div>
            <Label> Name:</Label>
            Tony Stark
        </div>
        <br />
        <div >
            <Label> E-mail:</Label>
            tony@company.com.au
        </div>
        <br />
        <div >
            <Label> Occupation:</Label>
            Software Developer
        </div>
        <br />
        <div >
            <Label> Status:</Label>
            Active
        </div>
    </div>
}
