import { DirectionalHint, Dropdown, DropdownMenuItemType, IconButton, Label, Stack, TextField, TooltipHost } from "@fluentui/react";
import { useState } from "react";

const options = ['Software Developer', 'Software Engineer', 'Web Developer', 'Web Designer', 'Database Administrator', 'Network Administrator', 'Systems Analyst', 'Systems Administrator', 'Project Manager']
    .map(occupation => ({ key: occupation, text: occupation }));

export default ({ change }) => {

    const occupationChange = (event, option) =>
        change(option);

    return <Dropdown
        options={options}
        label="Occupation"
        onChange={occupationChange}
        placeholder="Select a occupation"
    />
}
