import { ActionButton, CompoundButton, IconButton, Link, Stack } from '@fluentui/react';
import './navigator.component.css';
import { useEffect, useState } from "react";
import moment from 'moment';

export default ({ searching, change }) => {

    const [reference, setReference] = useState(new Date);
    const [exhibition, setExhibition] = useState(undefined);

    useEffect(() => {
        change(reference);
        instance();
    }, [reference]);

    const previous = () => {
        navigate(-1)
    }

    const next = () => {
        navigate(1)
    }

    const navigate = (increment) => {
        const response = new moment(reference).add(increment, 'days').toDate();
        setReference(response)
    }

    const instance = () => {
        const value = new moment(reference);

        setExhibition({
            day: value.date(),
            month: value.month(),
            year: value.year(),
            dayOfWeekName: value.format('dddd'),
            monthName: value.format('MMMM'),
        });
    }

    return <div className="ms-Grid">
        <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 navigatorContainer">
                <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} horizontal tokens={{ childrenGap: 5 }}>
                    <IconButton iconProps={{ iconName: 'CaretSolidLeft', style: { fontSize: 35 } }} disabled={searching} onClick={previous} />
                    <div style={{ textAlign: 'center', justifySelf: 'center' }} >
                        <div style={{ fontSize: 45, fontWeight: 'bold' }}>
                            {exhibition?.day}
                        </div>
                        <div>
                            {exhibition?.monthName}
                        </div>
                    </div>
                    <IconButton iconProps={{ iconName: 'CaretSolidRight', style: { fontSize: 35 } }} disabled={searching} onClick={next} />
                </Stack>
            </div>
        </div>
    </div>
}