import { CheckboxVisibility, ColumnActionsMode, DetailsList, DetailsListLayoutMode, FontIcon, MarqueeSelection, ProgressIndicator, Selection, SelectionMode, Text } from '@fluentui/react'
import TimetableStatusComponent from './timetable-status.component';
import DurationComponent from '../../common/components/duration.component';
import TimetableTableActionComponent from './timetable-table-action.component'
import { NEVER } from 'rxjs';

export default ({ searching, entities, select, refresh }) => {

    const columns = [
        {
            key: "id",
            name: "Project",
            onRender: item => <>
                <Text variant='mediumPlus'>
                    Project: <b>{item.Project.SequenceId}</b>
                </Text>
                <div>
                    <Text variant='medium'>
                        Location: {item.Project.Location}
                    </Text>
                </div>
            </>,
            isResizable: true,
            isCollapsable: true
            // targetWidthProportion: 1,
            // flexGrow: 1

        },
        {
            key: "date",
            name: "Date",
            onRender: item => <>
                <Text variant='mediumPlus'>
                    {item.Day} {item.Month}
                </Text>
                <div>
                    <Text variant='medium'>
                        {item.WeekDay}
                    </Text>
                </div>
            </>,
            isResizable: true,
            columnActionsMode: ColumnActionsMode.disabled,
        },
        {
            key: "quantity",
            name: "Quantity",
            onRender: (item) => <DurationComponent quantity={item.Quantity} />,
            isResizable: true,
        },
        {
            key: "status",
            name: "Status",
            onRender: item => <TimetableStatusComponent confirmed={item.Confirmed} submitted={item.Submitted} />,
            isResizable: true,
        },
        {
            key: "action",
            onRender: item => <TimetableTableActionComponent item={item} refresh={refresh} />,
            isResizable: true,
        }
    ]

    const selection = new Selection({
        selectionMode: SelectionMode.multiple,
        onSelectionChanged: () => select ? select(selection.getSelection()) : NEVER,
        canSelectItem: (item) => !item.Confirmed
    })

    if (searching)
        return <ProgressIndicator label="Timetable items" description="Retrieving timetable items..." />;

    if (Array.isArray(entities))
        return <MarqueeSelection selection={selection} >
            <DetailsList
                items={entities}
                columns={columns}
                selection={selection}
                selectionMode={SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
            />
        </MarqueeSelection>
}