import { CommandBar, DefaultButton, DefaultEffects, Pivot, PivotItem } from "@fluentui/react"
import MemberDetailComponent from '../components/member/member-detail.component'
import AvailabilityComponent from "../components/availability/availability.component"
import AppointmentComponent from "../components/appointment/appointment.component"
import { useNavigate } from "react-router-dom"

export default () => {

    const navigate = useNavigate();

    const back = () =>
        navigate('/member')

    return <>
        <CommandBar farItems={[{ key: 'back', iconOnly: true, iconProps: { iconName: 'Cancel' }, onClick: back }]}>
        </CommandBar>

        <div style={{ marginTop: 10, boxShadow: DefaultEffects.elevation8 }}>
            <Pivot>
                <PivotItem headerText="Member Detail" itemIcon="CustomList">
                    <div style={{ padding: 15 }}>
                        <MemberDetailComponent />
                    </div>
                </PivotItem>
                <PivotItem headerText="Member Availability" itemIcon="EventDate">
                    <div style={{ padding: 15 }}>
                        <AvailabilityComponent />
                    </div>
                </PivotItem>
                <PivotItem headerText="Member Appointments" itemIcon="Calendar">
                    <div style={{ padding: 15 }}>
                        <AppointmentComponent project={true} />
                    </div>
                </PivotItem>
            </Pivot>
        </div>

        <div className="ms-Grid" dir="rtl">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DefaultButton
                        dir="ltr"
                        text='Cancel'
                        style={{ margin: '10px 0px' }}
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={back}
                    />
                </div>
            </div>
        </div>
    </>
}
