
// const service = `${process.env.EXPO_PUBLIC_SERVICE_URL}/api/operator-agreement`;
const service = `https://localhost:3000`;


const create = async () => {
    return { success: true }
}

export const SupportService = {
    create
}
