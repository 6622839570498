import { useState } from "react";
import { ProjectService } from "../../services/project.service";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";

export default ({ searching, refresh }) => {

    const [creating, setCreating] = useState(undefined);

    const create = () => {

        const complete = () =>
            setTimeout(() => {
                setCreating(false);
                refresh()
            }, 1000);

        setCreating(true);

        ProjectService.create({})
            .then(response => console.log(response))
            .finally(complete)
    }

    return <PrimaryButton
        text={creating ? 'Creating...' : 'New Project'}
        onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : undefined}
        onClick={create}
        disabled={searching || creating}
    />
}