import axios from 'axios';
import moment from 'moment';
import { v4 } from 'uuid';
import { MemberService } from './member.service';
import { ProjectService } from './project.service';

const entities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, index) => {

    const availability = [1, 2, 3].includes(index)
        ? true : [7, 9].includes(index) ? false : undefined

    const reference = moment().add(index, 'days')
    return {
        Id: v4(),
        Date: reference.format('DD-MM-YYYY'),
        Day: reference.date(),
        WeekDay: reference.format('dddd'),
        Month: reference.format('MMMM'),
        Description: availability ? undefined : availability == false ? 'Sick leave' : undefined,
        Member: MemberService.entities[index],
        Project: ProjectService.entities[index],
        Availability: availability
    }
});
// const service = `${process.env.EXPO_PUBLIC_SERVICE_URL}/api/operator-agreement`;
const service = `https://localhost:3000`;

const search = async (uuid) => {
    return entities.map(entity => ({
        ...entity,
        Member: MemberService.entities.find(member => member.Id === uuid)
    }))
}

const create = async (availability) => {
    return { success: true }
}

export const AvailabilityService = {
    entities,
    create,
    search,
}
