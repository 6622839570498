import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, Persona, PersonaSize, ProgressIndicator, Selection, SelectionMode } from "@fluentui/react";
import MemberTableActionComponent from "./member-table-action.component";
import { Label } from "@fluentui/react"
import MemberStatusComponent from "./member-status.component";
export default ({ entities, searching, refresh }) => {

    const columns = [
        {
            key: "avatar",
            onRender: item => <>
                <Persona imageInitials={item.Initials} size={PersonaSize.size32} />
            </>,
            isCollapsable: true,
            maxWidth: 10,
            isResizable: false,
        },
        {
            key: "name",
            name: "Name",
            fieldName: 'Name',
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "phone",
            name: "PhoneNumber",
            fieldName: "PhoneNumber",
            maxWidth: 200,

            isResizable: true,
        },
        {
            key: "email",
            name: "Email",
            fieldName: "Mail",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "occupation",
            name: "Occupation",
            fieldName: "Occupation",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "status",
            name: "Status",
            onRender: item => <MemberStatusComponent active={item.Active} />,
        },
        {
            key: "action",
            isResizable: true,
            onRender: item => <MemberTableActionComponent item={item} />,
        }
    ]

    if (searching)
        return <ProgressIndicator label="Members" description="Retrieving members from organization..." />;

    if (Array.isArray(entities))
        return <DetailsList
            items={entities}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
        />
}