import { CheckboxVisibility, ColumnActionsMode, DetailsList, DetailsListLayoutMode, FontIcon, Label, MarqueeSelection, Persona, PersonaPresence, PersonaSize, ProgressIndicator, Selection, SelectionMode, Text } from '@fluentui/react';
import { NEVER } from 'rxjs';
import AvailabilityStatusComponent from './availability-status.component';
export default ({ searching, entities }) => {

    const columns = [
        {
            key: "date",
            name: "Date",
            onRender: item => <>
                <Text variant='mediumPlus'>
                    {item.Day} {item.Month}
                </Text>
                <div>
                    <Text variant='medium'>
                        {item.WeekDay}
                    </Text>
                </div>
            </>,
            isResizable: true,
            columnActionsMode: ColumnActionsMode.disabled,
            maxWidth: 120,
            minWidth: 120,
        },
        {
            key: "status",
            name: "Status",
            onRender: (item) => <AvailabilityStatusComponent availability={item.Availability} />,
            maxWidth: 150,
            minWidth: 150,
        },
        {
            key: "description",
            name: "Description",
            onRender: item => <Text variant='medium'>
                {item.Description}
            </Text>,
        },
        {
            key: "action",
            onRender: item => <></>,
        }
    ]

    if (searching)
        return <ProgressIndicator label="Availability items" description="Retrieving availability items..." />;

    if (Array.isArray(entities))
        return <DetailsList
            isHeaderVisible={true}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            items={entities}
            flexMargin={100}
            columns={columns}
        />
}