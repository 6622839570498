import { DefaultButton, IconButton, MaskedTextField, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import { MemberService } from "../../services/member.service";
import { useState } from "react";
import OccupationSelectorComponent from "../../common/components/occupation-selector.component";

export default ({ searching, refresh }) => {

    const [creating, setCreating] = useState(undefined);
    const [visibility, setVisibility] = useState(undefined);
    const [entity, setEntity] = useState(undefined);

    const create = () => {

        const complete = () =>
            setTimeout(() => {
                setCreating(false);
                inactivate();
                refresh()
            }, 1000);

        setCreating(true);

        MemberService.create({})
            .then(response => console.log(response))
            .finally(complete)
    }


    const activate = () =>
        setVisibility(true);

    const inactivate = () =>
        setVisibility(false);


    return <>
        <PrimaryButton
            text={creating ? 'Creating...' : 'New Member'}
            onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : undefined}
            onClick={activate}
            disabled={searching || creating}
        />

        <Modal isOpen={visibility} onDismiss={inactivate} isBlocking={true} >
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex' }} >
                    <h2>
                        New Member
                    </h2>
                    <div style={{ display: 'flex', flex: 1 }}>

                    </div>
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={inactivate}
                    />
                </div>
                <div >
                    <TextField label="First Name" required />
                    <TextField label="Last Name" required />
                    <TextField label="Mail" required />
                    <MaskedTextField label="Phone Number" mask="(99) 9999 999 999" />
                    <OccupationSelectorComponent />
                </div>

                <div style={{ marginTop: 40 }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <div style={{ flex: 1 }}>
                        </div>
                        <PrimaryButton
                            text={creating ? 'Creating...' : 'New Member'}
                            onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : undefined}
                            onClick={create}
                            disabled={searching || creating}
                        />

                        <DefaultButton text='Cancel' iconName='Cancel' onClick={inactivate} />

                    </Stack>

                </div>
            </div>
        </Modal>
    </>
}