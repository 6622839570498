import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, Label, ProgressIndicator, SelectionMode } from "@fluentui/react";
import TeamTableActionComponent from "./team-table-action.component";

export default ({ entities, searching, refresh }) => {

    const columns = [
        {
            key: "name",
            name: "Name",
            onRender: item => <Label>
                {item.FirstName} <b>{item.LastName}</b>
            </Label>,
            isResizable: true,
        },
        {
            key: "phone",
            name: "PhoneNumber",
            fieldName: "PhoneNumber",
            isResizable: true,
        },
        {
            key: "mail",
            name: "Mail",
            fieldName: "Mail",
            minWidth: 200,
            isResizable: true,
        },
        {
            key: "occupation",
            name: "Occupation",
            fieldName: "Occupation",
            isResizable: true,
        },
        {
            key: "action",
            isResizable: true,
            onRender: item => <TeamTableActionComponent item={item} />,
        }
    ]

    if (searching)
        return <ProgressIndicator label="Team Members" description="Retrieving team members from this project..." />;

    if (Array.isArray(entities))
        return <DetailsList
            items={entities}
            columns={columns}
            checkboxVisibility={CheckboxVisibility.always}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
        />
}