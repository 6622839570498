import { Label } from "@fluentui/react"

export default () => {
    return <>
        <div>
            <Label> Name:</Label>
            Tony Stark
        </div>
        <br />
        <div >
            <Label> E-mail:</Label>
            tony@company.com.au
        </div>
        <br />
        <div >
            <Label> Phone Number: </Label>
            +61 0123 456 789
        </div>
        <br />

        <div >
            <Label> Occupation:</Label>
            Software Developer
        </div>
        <br />
        <div >
            <Label> Status:</Label>
            Active
        </div>
    </>
}