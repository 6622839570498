import { Text } from "@fluentui/react";

export default ({ quantity }) => {

    if (!quantity)
        return;

    if (quantity == 1)
        return <Text>
            {quantity}
            <b> hour</b>
        </Text>

    return <Text>
        {quantity}
        <b> hours</b>
    </Text>
}