import { useEffect, useState } from 'react';
import { AvailabilityService } from '../../services/availability.service';
import AvailabilityFilterComponent from './availability-filter.component';
import AvailabilityTableComponent from './availability-table.component';

export default () => {

    const [entities, setEntities] = useState(undefined);
    const [selection, setSelection] = useState(undefined);
    const [searching, setSearching] = useState(undefined);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setSearching(true)
        const complete = () => setTimeout(() => setSearching(false), 1000);
        AvailabilityService.search(2).then((response) => setEntities(response)).finally(complete);
        setSelection(undefined)
    }

    return <div>
        <AvailabilityFilterComponent searching={searching} refresh={refresh}>
        </AvailabilityFilterComponent>

        <AvailabilityTableComponent searching={searching} entities={entities} select={setSelection} >
        </AvailabilityTableComponent>
    </div>
}