import axios from 'axios';
import moment from 'moment';
import { v4 } from 'uuid';
import { MemberService } from './member.service';
import { ProjectService } from './project.service';

const entities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i, index) => {

    const confirmation = [1, 4, 5].includes(index);

    const reference = moment().add(index, 'days')

    return {
        Id: v4(),
        Date: reference.format('DD-MM-YYYY'),
        Day: reference.date(),
        WeekDay: reference.format('dddd'),
        Month: reference.format('MMMM'),
        Project: ProjectService.entities[0],
        Member: MemberService.entities[0],
        Quantity: confirmation ? index % 2 ? 7 : 8 : undefined,
        Confirmed: confirmation,
        Submitted: confirmation && index == 4
    }
});
// const service = `${process.env.EXPO_PUBLIC_SERVICE_URL}/api/operator-agreement`;
const service = `https://localhost:3000`;

const search = async (reference) => {
    return entities;
    // const endpoint = `${service}/check`;
    // const response = await axios.get(endpoint);
    // return response.data;
}

const create = async (availability) => {
    return { success: true }
}

export const AppointmentService = {
    entities,
    create,
    search
}
