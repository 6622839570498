import { ActionButton, DefaultButton, IconButton, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react"
import { useState } from "react";

export default ({ action, selection, refresh }) => {

    const [confirming, setConfirming] = useState(undefined);
    const [visibility, setVisibility] = useState(undefined);
    const [value, setValue] = useState(undefined);

    const activate = () =>
        setVisibility(true);

    const inactivate = () =>
        setVisibility(false);

    const save = () => {

        console.log('saving');
        setConfirming(true);

        setTimeout(() => {
            setConfirming(false);
            inactivate();
            refresh()
        }, 1000);
    }

    if (Array.isArray(selection))
        if (selection.length > 0)
            return <>
                {
                    !action ?
                        <PrimaryButton
                            text={confirming ? 'Confirming' : `Confirm ${selection.length} ${selection.length > 1 ? 'items' : 'item'}`}
                            onRenderIcon={() => confirming ? <Spinner size={SpinnerSize.small} /> : undefined}
                            disabled={confirming} onClick={activate}
                        />

                        : <ActionButton iconProps={{ iconName: 'EventAccepted' }} onClick={activate} >
                            Confirm
                        </ActionButton>
                }
                <Modal isOpen={visibility} onDismiss={inactivate} isBlocking={true} >
                    <div style={{ padding: 10 }}>
                        <div style={{ display: 'flex' }} >
                            <h2>
                                Confirming {selection.length} {selection.length == 1 ? 'Appointment' : 'Appointments'}
                            </h2>
                            <div style={{ display: 'flex', flex: 1 }}>
                            </div>
                            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={inactivate} />
                        </div>

                        <TextField label="Quantity" required onChange={(e, value) => setValue(value)} validateOnLoad={false} suffix="hours"
                            onGetErrorMessage={value => !value ? 'You must provide a quantity' : undefined} />

                        <div style={{ marginTop: 40 }}>
                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                <div style={{ flex: 1 }}>
                                </div>
                                <PrimaryButton
                                    text={confirming ? 'Saving...' : 'Save'}
                                    onRenderIcon={() => confirming ? <Spinner size={SpinnerSize.small} /> : undefined}
                                    onClick={save}
                                    disabled={!value || confirming}
                                />

                                <DefaultButton text='Cancel' iconName='Cancel' onClick={inactivate} />
                            </Stack>

                        </div>
                    </div>
                </Modal>
            </>
}
