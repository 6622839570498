import { DatePicker, DefaultButton, IconButton, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, defaultDatePickerStrings } from "@fluentui/react"
import { useState } from "react";
import { AppointmentService } from "../../services/appointment.service";
import MemberSelectorComponent from "../member/member-selector.component";

export default ({ searching, refresh }) => {

    const [creating, setCreating] = useState(undefined);
    const [visibility, setVisibility] = useState(undefined);
    const [members, setMembers] = useState(undefined);
    const [date, setDate] = useState(undefined);

    const activate = () =>
        setVisibility(true);

    const inactivate = () =>
        setVisibility(false);

    const create = () => {

        const complete = () =>
            setTimeout(() => {
                inactivate();
                setCreating(false);
                refresh()
            }, 1000);

        setCreating(true);

        AppointmentService.create({})
            .then(response => console.log(response))
            .finally(complete)
    }

    return <>
        <PrimaryButton
            text={creating ? 'Creating' : 'New Appointment'}
            onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : undefined}
            onClick={activate}
            disabled={searching || creating}
        />

        <Modal isOpen={visibility} onDismiss={inactivate} isBlocking={true} styles={{ width: 400 }} >
            <div style={{ padding: 10 }}>
                <div style={{ display: 'flex' }} >
                    <h2>
                        New Appointment
                    </h2>
                    <div style={{ display: 'flex', flex: 1 }}>
                    </div>
                    <IconButton iconProps={{ iconName: 'Cancel' }} onClick={inactivate} />
                </div>
                <DatePicker isRequired label="Date" placeholder="Select a date..." onSelectDate={e => setDate(e)} />

                <MemberSelectorComponent change={setMembers}>
                </MemberSelectorComponent>

                <div style={{ marginTop: 40 }}>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <div style={{ flex: 1 }}>
                        </div>
                        <PrimaryButton
                            text={creating ? 'Creating...' : 'Create'}
                            onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : undefined}
                            onClick={create}
                            disabled={!members?.length || !date || creating}
                        />

                        <DefaultButton text='Cancel' iconName='Cancel' onClick={inactivate} />
                    </Stack>
                </div>
            </div>
        </Modal>
    </>
}