import { IconButton } from "@fluentui/react";

export default ({ item }) => {

    const menuProps = {
        items: [
            {
                key: 'remove',
                text: 'Remove',
                iconProps: { iconName: 'Delete' },
            }
        ]
    };

    return <IconButton
        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 15 } }}
        menuProps={menuProps}
        disabled={false}
        checked={false}
    />
}