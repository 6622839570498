import { useAuth0 } from "@auth0/auth0-react";
import { ActionButton, CommandBar, CommandBarButton, CommandButton, ContextualMenuItemType, Icon, SearchBox, Text, concatStyleSets, memoizeFunction } from "@fluentui/react"
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default () => {

    const navigate = useNavigate();

    const { logout, user } = useAuth0();

    const items = [
        {
            key: "project",
            name: "Projects",
            iconProps: { iconName: "ClipboardListMirrored" },
            onClick: () => navigate('/project'),
            permission: 'read:projects'
        },
        {
            key: "member",
            name: "Members",
            iconProps: { iconName: "Contact" },
            onClick: () => navigate('/member'),
            permission: 'read:members'
        },
        {
            key: "availability",
            name: "My Availability",
            iconProps: { iconName: "EventDate" },
            onClick: () => navigate('/availability'),
            permission: 'read:availabilities'
        },
        {
            key: "timetable",
            name: "My Timetable",
            iconProps: { iconName: "DateTimeMirrored" },
            onClick: () => navigate('/timetable'),
            permission: 'read:timetable'
        },
    ]

    const settings = [
        {
            key: "settings",
            name: 'Settings',
            iconProps: { iconName: "Settings" },

            subMenuProps: {
                items: [

                    {
                        key: 'profile',
                        text: 'Profile',
                        iconProps: { iconName: 'IDBadge' },
                        onClick: () => navigate('/profile'),
                    },
                    {
                        key: 'user',
                        text: user?.email,
                        disabled: true
                    },
                    {
                        key: 'settings_divider',
                        itemType: ContextualMenuItemType.Divider
                    },
                    {
                        key: 'support',
                        text: 'Support',
                        iconProps: { iconName: 'Headset' },
                        onClick: () => navigate('/support'),
                    },
                    {
                        key: 'logout',
                        text: 'Logout',
                        iconProps: { iconName: 'SignOut' },
                        onClick: logout
                    },
                ],
            },
        },
    ]



    if (!user['http://evvo.dev.user/permissions'])
        return <CommandBar
            items={items}
            farItems={settings} />

    const authenticationItems = items.filter(item =>
        !item.permission || user['http://evvo.dev.user/permissions'].includes(item.permission));

    return <CommandBar
        items={authenticationItems}
        farItems={settings} />
}

