import './App.css';
import { initializeIcons } from '@fluentui/react';
import { Auth0Provider } from '@auth0/auth0-react';
import ApplicationStack from './stacks/application.stack';


const options = {
  redirect_uri: window.location.origin
}

function App() {

  initializeIcons();

  return <Auth0Provider domain="evvosolution.au.auth0.com" clientId="922pmdgIdfiO4dixPcEdEW2rGnIFAkvz" authorizationParams={options}>
    <ApplicationStack />
  </Auth0Provider>

}

export default App;
