import { DefaultButton, FontIcon, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react"
import { NEVER } from "rxjs"
import TimetableConfirmationComponent from "./timetable-confirmation.component"

export default ({ searching, selection, refresh }) => {

    return <Stack horizontal={true} wrap={true} tokens={{ childrenGap: 5 }} >
        <DefaultButton
            text={searching ? 'Refreshing' : 'Refresh'}
            onRenderIcon={() => searching ? <Spinner size={SpinnerSize.small} /> : <FontIcon iconName="Refresh" />}
            onClick={refresh}
            disabled={searching}
        />

        <TimetableConfirmationComponent selection={selection} refresh={refresh}>
        </TimetableConfirmationComponent>
    </Stack>
}