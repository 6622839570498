import { ActionButton, FontIcon } from "@fluentui/react"

export default ({ confirmed, submitted }) => {

    if (submitted)
        return <ActionButton iconProps={{ iconName: 'CompletedSolid' }}  >
            Submitted
        </ActionButton>

    if (confirmed)
        return <ActionButton iconProps={{ iconName: 'Completed' }}  >
            Confirmed
        </ActionButton>

    return <ActionButton iconProps={{ iconName: 'AlertSolid', style: { color: 'orange' } }}  >
        Pending
    </ActionButton>
}