import { ActionButton, SharedColors } from "@fluentui/react"

export default ({ availability }) => {

    if (availability)
        return <ActionButton iconProps={{ iconName: 'CircleShapeSolid', style: { color: 'green' } }}  >
            Available
        </ActionButton>

    else if (availability === false)
        return <ActionButton iconProps={{ iconName: 'CircleShapeSolid', style: { color: 'red' } }}  >
            Unavailable
        </ActionButton>

    return <ActionButton iconProps={{ iconName: 'CircleShapeSolid', style: { color: 'grey' } }}  >
        Undefined
    </ActionButton>
}