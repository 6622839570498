import { IconButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

export default ({ item }) => {

    const navigate = useNavigate();

    const menuProps = {
        items: [
            {
                key: 'update',
                text: 'Update',
                iconProps: { iconName: 'EventAccepted' },
                onClick: () => navigate('/project/1')
            }
        ],
        directionalHintFixed: true,
    };

    return <IconButton
        menuIconProps={{ iconName: 'MoreVertical', style: { fontSize: 15 } }}
        menuProps={menuProps}
        disabled={false}
        checked={false}
    />
}