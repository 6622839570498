import { NormalPeoplePicker } from "@fluentui/react"
import { useRef, useState } from "react";
import { people } from '@fluentui/example-data';

export default ({ change }) => {

    const picker = useRef(null);

    const [peopleList, setPeopleList] = useState(people);

    const suggestion = (query, current, limitResults = 5) => {
        const predicate = persona => {

            if (persona.secondaryText)
                if (persona.secondaryText.toLowerCase().includes(query.toLowerCase()))
                    return true;


            if (persona.text)
                if (persona.text.toLowerCase().includes(query.toLowerCase()))
                    return true;

            return false;
        }

        return !query
            ? filter(current)
            : filter(predicate).splice(0, 5)
    }

    const filter = (current) => {
        if (!Array.isArray(current))
            return peopleList;

        const uuids = current.map(o => o.key);
        return peopleList.filter(persona => !uuids.includes(persona?.key));
    }

    return <NormalPeoplePicker
        key={'normal'}
        onResolveSuggestions={suggestion}
        getTextFromItem={item => item.Name}
        onEmptyResolveSuggestions={filter}
        styles={{ root: { width: 400 } }}
        componentRef={picker}
        onChange={change}
    />
}