import axios from 'axios';
import moment from 'moment';
import { v4 } from 'uuid';
import { ProjectService } from './project.service';

const entities = [1, 2, 4, 5, 11, 22, 9].map((sequenceId, index) => {

    const confirmation = [1, 4, 5].includes(index);

    const reference = [0, 10].includes(index)
        ? moment().add(index + 1, 'days')
        : moment().add(-index, 'days')

    return {
        id: v4(),
        Project: index % 5 ? ProjectService.entities[0] : ProjectService.entities[1],
        Date: reference.format('DD-MM-YYYY'),
        Day: reference.date(),
        WeekDay: reference.format('dddd'),
        Month: reference.format('MMMM'),
        Quantity: confirmation ? index % 2 ? 7 : 8 : undefined,
        Confirmed: confirmation,
        Submitted: confirmation && index == 4
    }
});

// const service = `${process.env.EXPO_PUBLIC_SERVICE_URL}/api/operator-agreement`;
const service = `https://localhost:3000`;

const search = async () => {
    return entities;
    // const endpoint = `${service}/check`;
    // const response = await axios.get(endpoint);
    // return response.data;
}

export const TimetableService = {
    entities,
    search
}
