import AvailabilityFilterComponent from '../components/availability/availability-filter.component'
import AvailabilityCreateComponent from '../components/availability/availability-create.component'
import AvailabilityTableComponent from '../components/availability/availability-table.component'
import { useEffect, useState } from 'react';
import { AvailabilityService } from '../services/availability.service';

export default () => {

    const [entities, setEntities] = useState(undefined);
    const [searching, setSearching] = useState(undefined);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setSearching(true)
        const complete = () => setTimeout(() => setSearching(false), 1000);
        AvailabilityService.search(3).then((response) => setEntities(response)).finally(complete);
    }

    return <div>
        <AvailabilityFilterComponent searching={searching} refresh={refresh}>
            <AvailabilityCreateComponent searching={searching} refresh={refresh} />
        </AvailabilityFilterComponent>

        <AvailabilityTableComponent searching={searching} entities={entities}  >
        </AvailabilityTableComponent>
    </div>
}