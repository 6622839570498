import { v4 } from 'uuid'
import { MemberService } from './member.service'

// const service = `${process.env.EXPO_PUBLIC_SERVICE_URL}/api/operator-agreement`;
const service = `https://localhost:3000`;

const search = async () => {
    return [...MemberService.entities].splice(0, 3);
}

const create = async () => {
    return { success: true }
}

export const TeamService = {
    create,
    search
}
