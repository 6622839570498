import MemberFilterComponent from '../components/member/member-filter.component'
import MemberCreateComponent from '../components/member/member-create.component'
import MemberTableComponent from '../components/member/member-table.component'
import { useEffect, useState } from 'react';
import { MemberService } from '../services/member.service';

export default () => {

    const [entities, setEntities] = useState(undefined);
    const [searching, setSearching] = useState(undefined);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setSearching(true)
        const complete = () => setTimeout(() => setSearching(false), 1000);
        MemberService.search().then((response) => setEntities(response)).finally(complete);
    }

    return <div>
        <MemberFilterComponent refresh={refresh} searching={searching}>
            <MemberCreateComponent searching={searching} refresh={refresh} />
        </MemberFilterComponent>

        <MemberTableComponent searching={searching} entities={entities}>
        </MemberTableComponent>
    </div>
}
