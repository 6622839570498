import { DirectionalHint, Dropdown, DropdownMenuItemType, IconButton, Label, Stack, TextField, TooltipHost } from "@fluentui/react";
import { useState } from "react";

const options = [
    { key: 'general', text: 'General', itemType: DropdownMenuItemType.Header },
    { key: 'question', text: 'Question' },
    { key: 'suggestion', text: 'Suggestion' },
    { key: 'feedback', text: 'Feedback' },
    { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'ticket', text: 'Technical', itemType: DropdownMenuItemType.Header },
    { key: 'bug', text: 'Bugs and issues' },
    { key: 'change', text: 'Feature change' },
    { key: 'implementation', text: 'Feature implementation' },
    { key: 'divider2', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'other', text: 'Other' },
];

export default ({ change }) => {

    const [value, setValue] = useState(undefined);

    const LabelComponent = (props) => {
        return <Stack horizontal verticalAlign="center">
            <Label>
                Subject
            </Label>
            <TooltipHost directionalHint={DirectionalHint.rightCenter} content="Select the reason for your support ticket">
                <IconButton iconProps={{ iconName: 'Info' }} styles={{ root: { marginBottom: -3 } }} />
            </TooltipHost>
        </Stack>
    }

    const reasonChange = (event, option) => {
        console.log("🚀 ~ reasonChange ~ option:", option);
        setValue(option.key);

        if (option.key != 'other')
            change(option.text);

        else change(undefined);
    }

    const customReasonChange = (event, value) =>
        change(value)

    return <>
        <Dropdown
            options={options}
            onChange={reasonChange}
            placeholder="Select a subject"
            onRenderLabel={LabelComponent}
        />

        {
            value == 'other' && <TextField
                label="Other subject"
                onChange={customReasonChange}
                onGetErrorMessage={value => !value ? 'You must provide a reason' : undefined}
                validateOnLoad={false}
            />
        }
    </>
}
