import { v4 } from 'uuid'
import { MemberService } from './member.service'

const locations =
    [
        '123 High Street, Kew, VIC 3101',
        '456 Burke Road, Balwyn North, VIC 3104',
        '789 Camber Walk, Alphington, VIC 3078',
        '1011 High Street, Kew, VIC 3101',
        '1213 Burke Road, Balwyn North, VIC 3104',
        '1415 Camber Walk, Alphington, VIC 3078',
        '1617 High Street, Kew, VIC 3101',
        '1819 Burke Road, Balwyn North, VIC 3104',
        '2021 Camber Walk, Alphington, VIC 3078',
        '2223 High Street, Kew, VIC 3101',
        '2223 High Street, Kew, VIC 3101',
        '2223 High Street, Kew, VIC 3101',
        '2223 High Street, Kew, VIC 3101',
        '2223 High Street, Kew, VIC 3101',
        '2223 High Street, Kew, VIC 3101',
        '2223 High Street, Kew, VIC 3101',
    ]

const entities = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16].map((sequenceId, index) => {

    const location = locations[index];

    return {
        Id: v4(),
        SequenceId: sequenceId,
        Manager: MemberService.entities[5],
        Location: location,
        Active: ![5, 12, 13].includes(index)
    }
});

// const service = `${process.env.EXPO_PUBLIC_SERVICE_URL}/api/operator-agreement`;
const service = `https://localhost:3000`;

const search = async () => {
    return entities;
    // const endpoint = `${service}/check`;
    // const response = await axios.get(endpoint);
    // return response.data;
}

const create = async () => {
    return { success: true }
}

export const ProjectService = {
    entities,
    create,
    search
}
