import { useAuth0 } from "@auth0/auth0-react";
import LoadingComponent from "../common/components/loading.component"
import AuthenticatedStack from "./authenticated.stack";

export default () => {

    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    if (isLoading)
        return <LoadingComponent text='Authenticating user...' />

    else if (isAuthenticated)
        return <AuthenticatedStack />

    loginWithRedirect();
    return <LoadingComponent text='Loading...' />
}