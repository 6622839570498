import AppointmentFilterComponent from './appointment-filter.component'
import AppointmentCreateComponent from './appointment-create.component'
import AppointmentTableComponent from './appointment-table.component'
import { useEffect, useState } from 'react';
import { AppointmentService } from '../../services/appointment.service';

export default ({ member, project }) => {

    const [entities, setEntities] = useState(undefined);
    const [searching, setSearching] = useState(undefined);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setSearching(true)
        const complete = () => setTimeout(() => setSearching(false), 1000);
        AppointmentService.search().then((response) => setEntities(response)).finally(complete);
    }

    return <>
        <AppointmentFilterComponent>
            <AppointmentCreateComponent searching={searching} refresh={refresh} />
        </AppointmentFilterComponent>

        <AppointmentTableComponent searching={searching} entities={entities} member={member} project={project}>
        </AppointmentTableComponent>
    </>
}