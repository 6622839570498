import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, Persona, PersonaSize, ProgressIndicator, SelectionMode, Text } from "@fluentui/react";
import TimetableStatusComponent from "../timetable/timetable-status.component";

export default ({ member, project, entities, searching, refresh }) => {

    var columns = [
        {
            key: "member",
            name: "Member",
            onRender: item => <Persona
                text={item.Member.Name}
                secondaryText={item.Member.Occupation}
                size={PersonaSize.size40}
            />,
            isResizable: true,
        },
        {
            key: "project",
            name: "Project",
            onRender: item => <>
                <Text variant='mediumPlus'>
                    Project: <b>{item.Project.SequenceId}</b>
                </Text>
                <div>
                    <Text variant='medium'>
                        Location: {item.Project.Location}
                    </Text>
                </div>
            </>,
            isResizable: true,
            isCollapsable: true
        },
        {
            key: "date",
            name: "Date",
            onRender: item => <>
                <Text variant='mediumPlus'>
                    {item.Day} {item.Month}
                </Text>
                <div>
                    <Text variant='medium'>
                        {item.WeekDay}
                    </Text>
                </div>
            </>,
            isResizable: true,
        },
        {
            key: "quantity",
            name: "Quantity",
            onRender: item => {
                if (item.Quantity)
                    return <><b>{item.Quantity}</b> hours</>
            },
            isResizable: true,
        },
        {
            key: "status",
            name: "Status",
            onRender: item => <TimetableStatusComponent confirmed={item.Confirmed} submitted={item.Submitted} />,
            isResizable: true,
        },
        {
            key: "action",
            minWidth: 50
        }
    ]

    if (searching)
        return <ProgressIndicator label="Appointments" description="Retrieving appointments from this project..." />;


    if (!member)
        columns = columns.filter(column => column.key !== 'member')

    if (!project)
        columns = columns.filter(column => column.key !== 'project')

    if (Array.isArray(entities))
        return <DetailsList
            items={entities}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
        />
}