import TeamFilterComponent from './team-filter.component'
import TeamCreateComponent from './team-create.component'
import TeamTableComponent from './team-table.component'
import { useEffect, useState } from 'react';
import { TeamService } from '../../services/team.service';

export default ({ projectId }) => {

    const [entities, setEntities] = useState(undefined);
    const [searching, setSearching] = useState(undefined);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setSearching(true)
        const complete = () => setTimeout(() => setSearching(false), 1000);
        TeamService.search().then((response) => setEntities(response)).finally(complete);
    }

    return <>
        <TeamFilterComponent >
            <TeamCreateComponent searching={searching} refresh={refresh} />
        </TeamFilterComponent>
        <TeamTableComponent searching={searching} entities={entities}>
        </TeamTableComponent>
    </>
}