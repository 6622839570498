import { DefaultButton } from "@fluentui/react"


const menuProps = {
    items: [
        {
            key: 'export',
            text: 'Export Excel',
            iconProps: { iconName: 'ExcelDocument' },
        },
        {
            key: 'integration',
            text: 'SAP Integration',
            iconProps: { iconName: 'CloudUpload' },
        },
    ],
};

export default () => {
    return <DefaultButton
        text="Options"
        iconName="Delete"
        split={true}
        menuProps={menuProps}
    />
}