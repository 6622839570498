import { useState } from "react";
import { DefaultButton, FontIcon, PrimaryButton, Spinner, SpinnerSize, Stack, StackItem, TextField } from "@fluentui/react";
import SupportReasonSelectorComponent from "../components/support/support-reason-selector.component"
import { SupportService } from "../services/support.service";

export default () => {

    const [reason, setReason] = useState(undefined);
    const [content, setContent] = useState(undefined);
    const [creating, setCreating] = useState(undefined);

    const contentChange = (event, value) =>
        setContent(value);

    const create = () => {
        if (reason && content) {
            setCreating(true)
            const complete = () => setTimeout(() => { setCreating(false); clear() }, 1000);
            SupportService.create().finally(complete);
        }
    }

    const clear = () =>
        setContent(null);

    return <>
        <SupportReasonSelectorComponent change={setReason} />

        <TextField label="Content"
            rows={10}
            maxLength={500}
            multiline={true}
            value={content}
            onChange={contentChange}
            onGetErrorMessage={value => !value ? 'You must provide a description for your ticket' : undefined}
            validateOnLoad={false}
        />

        <div style={{ marginTop: 10 }} className="ms-Grid" >
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <PrimaryButton
                            text={creating ? 'Submitting' : 'Submit'}
                            onRenderIcon={() => creating ? <Spinner size={SpinnerSize.small} /> : <FontIcon iconName="Mail" />}
                            onClick={create}
                            disabled={creating || !reason || !content}
                        />

                        <DefaultButton
                            text='Clear'
                            iconProps={{ iconName: 'ClearFormatting' }}
                            onClick={clear}
                        />
                    </Stack>
                </div>
            </div>
        </div>
    </>
}
