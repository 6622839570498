import { useEffect, useState } from "react";
import ScheduleFilterComponent from "../components/timetable/timetable-filter.component"
import ScheduleTableComponent from "../components/timetable/timetable-table.component"
import ScheduleOptionComponent from "../components/timetable/timetable-option.component"
import { TimetableService } from '../services/timetable.service';

export default () => {

    const [entities, setEntities] = useState(undefined);
    const [selection, setSelection] = useState(undefined);
    const [searching, setSearching] = useState(undefined);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setSearching(true)
        const complete = () => setTimeout(() => setSearching(false), 1000);
        TimetableService.search().then((response) => setEntities(response)).finally(complete);
        setSelection(undefined)
    }

    return <div>
        <ScheduleFilterComponent>
            <ScheduleOptionComponent searching={searching} selection={selection} refresh={refresh} />
        </ScheduleFilterComponent>

        <ScheduleTableComponent searching={searching} entities={entities} select={setSelection} refresh={refresh} >
        </ScheduleTableComponent>
    </div>
}
