import ProjectDetailComponent from '../components/project/project-detail.component'
import AppointmentComponent from '../components/appointment/appointment.component'
import TeamComponent from '../components/team/team.component'
import { CommandBar, DefaultButton, DefaultEffects, Label, Pivot, PivotItem } from '@fluentui/react'
import './project-update.page.css'
import { useNavigate } from 'react-router-dom'

export default () => {

    const navigate = useNavigate();

    const back = () =>
        navigate('/project')

    return <>
        <CommandBar farItems={[{ key: 'back', iconOnly: true, iconProps: { iconName: 'Cancel' }, onClick: back }]}>
        </CommandBar>

        <div style={{ marginTop: 10, boxShadow: DefaultEffects.elevation8 }}>
            <Pivot defaultSelectedKey={1} >
                <PivotItem itemKey={1} headerText="Project Detail" itemIcon="CustomList">
                    <div style={{ padding: 15 }}>
                        <ProjectDetailComponent />
                    </div>
                </PivotItem>
                <PivotItem itemKey={2} headerText="Project Team" itemIcon="Contact">
                    <div style={{ padding: 15 }}>
                        <TeamComponent />
                    </div>
                </PivotItem>
                <PivotItem itemKey={3} headerText="Project Appointments" itemIcon="Calendar" >
                    <div style={{ padding: 15 }}>
                        <AppointmentComponent member={true} >
                        </AppointmentComponent>
                    </div>
                </PivotItem>
            </Pivot>
        </div>

        <div className="ms-Grid" dir="rtl">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DefaultButton
                        dir="ltr"
                        text='Cancel'
                        style={{ margin: '10px 0px' }}
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={back}
                    />
                </div>
            </div>
        </div>
    </>
}