import { Label } from "@fluentui/react"

export default () => {
    return <div>
        <div>
            <Label> Project Name:</Label>
            Kew Recreation Centre
        </div>
        <br />
        <div >
            <Label>Location:</Label>
            375 High Street, Kew, VIC 3101
        </div>
        <br />
        <div >
            <Label> Responsible Person: </Label>
            Tony Stark
        </div>
        <br />
        <div >
            <Label> Phone Number: </Label>
            +61 0123 456 789
        </div>
        <br />
        <div >
            <Label> Status:</Label>
            Active
        </div>
    </div>
}