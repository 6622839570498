export default ({ children }) => {
    return <div >
        <div className="ms-Grid" dir="rtl">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {children}
                </div>
            </div>
        </div>
    </div>
}